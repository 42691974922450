.navLogo {
    height: 40px;
    vertical-align: middle;
    margin-top: -4px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.headers {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.logo {
    margin-right: 20px;
}

.antdHeader {
    background-color: #fff;
    /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02); */
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
    display: flex;
    align-items: center;
}

.antdContent {
    /* padding: 0 50px; */
    background-color: #fff;
}

.userMenu {
    z-index: 999;
}

.linkMenu {
    min-width: 500px;

    font-weight: 500;

}

.userMenu .ant-menu .ant-menu-vertical :where {
    border-inline-end: none !important;
}